import axios from 'axios';
import React, { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export default function AssociatePartnerForm() {
    // Form state for each input field
    const nav = useNavigate();
    const [formData, setFormData] = useState({
        accountId: '',
        siteId: '',
        region: '',
        partnerName: '',
        adminEmail: '',
        roleType: '',
        user: ''
    });
    const [val, setVal] = useState(0);

    const back = () =>{
        nav('/tallydashboard');
    }

    // Handle change in form fields
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form Data:', formData);
        // Add form submission logic here
        axios.post(`${process.env.REACT_APP_TALLY_API}/apformdata`, formData)
            .then(res => console.log(res.data))
            .catch(err => console.log(err) )
            alert('AP successfully Created');
            setVal(val + 1)
            formData.accountId= '';
        formData.siteId= '';
        formData.region= '';
        formData.partnerName= '';
        formData.adminEmail= '';
        formData.roleType= '';
        formData.user= '';
    };



    return (
        <div style={formContainerStyle} key={val}>
            <FaArrowLeft onClick={back} style={{cursor:'pointer'}} />
            <h2 style={formHeaderStyle}>Associate Partner Form</h2>
            
            <form onSubmit={handleSubmit} style={formStyle}>
                {/* First column */}
                <div style={columnStyle}>
                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="accountId">Account ID:</label>
                        <input
                            type="text"
                            id="accountId"
                            name="accountId"
                            placeholder='Account_id...'
                            style={inputStyle}
                            value={formData.accountId}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="siteId">Site ID:</label>
                        <input
                            type="text"
                            id="siteId"
                            name="siteId"
                            placeholder='Site_id...'
                            style={inputStyle}
                            value={formData.siteId}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="region">Region:</label>
                        <input
                            type="text"
                            id="region"
                            name="region"
                            placeholder='Region...'
                            style={inputStyle}
                            value={formData.region}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="region">User:</label>
                        <input
                            type="text"
                            id="user"
                            name="user"
                            placeholder='User...'
                            style={inputStyle}
                            value={formData.user}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                {/* Second column */}
                <div style={columnStyle}>
                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="partnerName">Partner Name:</label>
                        <input
                            type="text"
                            id="partnerName"
                            name="partnerName"
                            placeholder='PartnerName...'
                            style={inputStyle}
                            value={formData.partnerName}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="adminEmail">Partner Account Admin Email ID:</label>
                        <input
                            type="email"
                            id="adminEmail"
                            name="adminEmail"
                            placeholder='Partner Account Admin Email...'
                            style={inputStyle}
                            value={formData.adminEmail}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle} htmlFor="roleType">Role Type:</label>
                        <select
                            id="roleType"
                            name="roleType"
                            style={inputStyle}
                            value={formData.roleType}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select Role Type</option>
                            <option value="AP">AP</option>
                            <option value="Star AP">Star AP</option>
                        </select>
                    </div>
                </div>

                {/* Submit button */}
                <div style={submitButtonContainerStyle}>
                    <button
                        type="submit"
                        style={submitButtonStyle}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

// Inline styles
const formContainerStyle = {
    padding: '2rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '7rem auto'
};

const formHeaderStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center'
};

const formStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.2rem',
};

const columnStyle = {
    flex: 1
};

const formGroupStyle = {
    marginBottom: '1rem'
};

const labelStyle = {
    display: 'block',
    fontWeight: '500',
    marginBottom: '0.5rem'
};
const inputStyle = {
    width: '90%',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid rgb(42, 43, 42)',
    outline: 'none'
};

const submitButtonContainerStyle = {
    gridColumn: 'span 2',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5rem'
};

const submitButtonStyle = {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold'
};