import './App.css';
import  { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './login';
import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import Menu from './menu';
import Details from './details';
import Dashboard from './tallydashboard';
import UserRegistrationForm from './users';
import Update from './updateData';
import PartnerForm from './sample';
import AssociatePartnerForm from './apusers';

export const region = createContext()
function App() {
  const [sampleData,setsampleData] = useState([]);
  const [alldata,setallData] = useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_TALLY_API}/region`).then((res)=>{
      setsampleData(res.data);
    
})
  },[])

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_TALLY_API}/alldata`).then((res)=>{
      setallData(res.data);
    
})
  },[])
  return (
    <div>
      <BrowserRouter>
    
      <Menu/>
      <region.Provider value={{sampleData,setsampleData,alldata,setallData}}>

      
      <Routes>
        <Route path="/" element={<Login/>}></Route>
     
       <Route path="details" element={<Details/>}></Route>
        <Route path="tallydashboard" element={<Dashboard/>}></Route>
       
        <Route path="updateData" element={<Update/>}></Route>
        <Route path="sample" element={<PartnerForm/>}></Route>
        <Route path="tallydashboard/users" element={<UserRegistrationForm/>}></Route>
        <Route path="tallydashboard/apusers" element={<AssociatePartnerForm/>}></Route>
      </Routes>
      </region.Provider>
      </BrowserRouter>

    </div>
  );
}

export default App;
