import React, { useContext, useState } from "react"
import { region } from "./App";
//import Select from 'react-select';
import axios from "axios";
import { useLocation } from "react-router-dom";
import './css/details.css'

export default function Details(props) {
    const location = useLocation();
    const { username, id } = location.state;
    console.log(username, id)

    const { sampleData, alldata } = useContext(region);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [data, setData] = useState([]);
    const [details, setDetails] = useState([]);
    //const [activities, setActivities] = useState([]); // Initialize activities state as an empty array
    // const [leadType, setLeadType] = useState([]);
    //const [selectedLeadTypes, setSelectedLeadTypes] = useState([]);
    //const [leadsgenerated, setLeadsGenerated] = useState(null);


    const [val, setVal] = useState(0);

    const initialState = {
        eInvoicing: '',
        eWayBill: '',
        dashboard: '',
        excelImport: '',
        whatsappOnTallyPrime: '',
        tallyPrimeOnAWS: '',
        msmeReports: '',
        invoicingWithLogo: '',
        qrCodeEnablementForPayment: '',
        priceList: '',
        CustomisedTermsandConditionsinInvoices:''
    };

    const labels = {
        eInvoicing: 'E-Invoicing',
        eWayBill: 'E-Way Bill',
        dashboard: 'Dashboard',
        excelImport: 'Excel Import',
        whatsappOnTallyPrime: 'WhatsApp on Tally Prime',
        tallyPrimeOnAWS: 'Tally Prime on AWS',
        msmeReports: 'MSME Reports',
        invoicingWithLogo: 'Invoicing with Logo',
        qrCodeEnablementForPayment: 'QR Code Enablement for Payment',
        priceList: 'Price List',
        CustomisedTermsandConditionsinInvoices:'Customised Terms & Conditions in Invoices'
    };



    const [checkboxes, setCheckboxes] = useState(initialState);

    const [checkedLeadTypes, setCheckedLeadTypes] = useState([]);


    const handleOptionCheckbox = (event) => {
        const { name, value } = event.target;
        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: value,

            
        }));
        console.log(checkboxes)

    };

    const handleLeadTypeChange = (event) => {
        const { name, checked } = event.target;
    
        if (checked) {
            setCheckedLeadTypes((prevChecked) => [...prevChecked, name]);
        } else {
            setCheckedLeadTypes((prevChecked) => prevChecked.filter((item) => item !== name));
        }

        console.log(checkedLeadTypes);
    };

    const activities = selectedOptions.join(',')
    const leadTypeValues = checkedLeadTypes.join(', ');

    
    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_TALLY_API}/singleuser/${id}`).then((res) => {

            setDetails({ username: res.data[0].username, email: res.data[0].email, cp: res.data[0].cp, location: res.data[0].location })
            console.log(res.data[0].cp);
        }
        )
    }, [id])
    const handler = (e) => {
        const { name, value } = e.target;

        setData((prevState) =>
            ({ ...prevState, [name]: value }));

        console.log(data);


    }

    // const handleSelectChange = (selectedOptions) => {
    //     if (Array.isArray(selectedOptions)) {
    //         const selectedValues = selectedOptions.map(item => item.value);
    //         setActivities(selectedValues); // Set activities using setActivities
    //         setData((prevState) => ({ ...prevState, activities: selectedValues }));
    //         console.log(selectedValues);
    //     }
    // };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedOptions((prevSelectedOptions) => {
            if (checked) {
                return [...prevSelectedOptions, value];
            } else {
                return prevSelectedOptions.filter((option) => option !== value);
            }

            
        });
        console.log(selectedOptions);
    };



    // const handleSelectleadsType = (selectedOptions) => {
    //     if (Array.isArray(selectedOptions)) {
    //         const selectedValues = selectedOptions.map(item => item.value);
    //         setLeadType(selectedValues);
    //         console.log(leadType);

    //     }
    // };

    // const handleLeadsChange = (selectedOption) => {
    //     setLeadsGenerated(selectedOption.value);
    //     setData((prevState) => ({ ...prevState, leadsgenerated: selectedOption.value }));
    // };

    const options = [
        { value: "Customer Calling", label: "Customer Calling  " },
        { value: "Release Update", label: "Release Update" },
        { value: " New LOB update ", label: " New LOB update " },
        { value: " AP Enablement Tally feature ", label: " AP Enablement Tally feature " },
        { value: "Customer Profile Update ", label: "Customer Profile Update " },
        { value: "Marketing Activities ", label: "Marketing Activities " },
        { value: "Others", label: "Others" }
    ]

    // const leads = [
    //     { value: "Yes", label: "Yes" },
    //     { value: "No", label: "No" }
    // ]

    // const leadTypeOptions = [
    //     { value: "TP", label: "TP" },
    //     { value: "TSS", label: "TSS" },
    //     { value: "Others", label: "Others" }
    // ];



    const filteredPartners = alldata
        .filter(data => data.assigned_cp === username)
        .map(data => data.partner_name);
    console.log(filteredPartners);
    const uniquePartners = Array.from(new Set(filteredPartners));


    const tallyrec = (e) => {

        e.preventDefault();
        // Define the required fields
        const requiredFields = [
            'partnername',

            'remarks'
        ];
        console.log(requiredFields);
        // Check if any required field is empty
        const isAnyFieldEmpty = requiredFields.some((field) => {
            return !data[field]; // This returns true if the field is empty
        });

        // If any required field is empty, alert the user
        if (isAnyFieldEmpty) {
            alert('Please fill all fields. ');
            return;
        }



        const userdetails = { partnername: data.partnername, cp: data.cp, location: data.location, dateofvisit: data.dateofvisit, activities: activities, leadtype: leadTypeValues, eInvoicing: checkboxes.eInvoicing, eWayBill: checkboxes.eWayBill, dashboard: checkboxes.dashboard, excelImport: checkboxes.excelImport, whatsappOnTallyPrime: checkboxes.whatsappOnTallyPrime, tallyPrimeOnAWS: checkboxes.tallyPrimeOnAWS, msmeReports: checkboxes.msmeReports, invoicingWithLogo: checkboxes.invoicingWithLogo, qrCodeEnablementForPayment: checkboxes.qrCodeEnablementForPayment, priceList: checkboxes.priceList,CustomisedTermsandConditionsinInvoices: checkboxes.CustomisedTermsandConditionsinInvoices, remarks: data.remarks }



        console.log(userdetails);

        axios.post(`${process.env.REACT_APP_TALLY_API}/data`, { userdetails, username, details }).then((res) => {
            console.log(res.data);


            if (res !== '') {
                alert('registration successfully completed');

                setVal(val + 1)
                data.partnername = '';
                data.dataofvisit = '';
                data.activities = '';
                data.leadsgenerated = '';
                data.leadtype = '';
                data.leadsnumber = '';
                data.yearofinc = '';
                data.presturnover = '';
                data.presturnover = '';
                data.topbottomh_w = '';
                data.topbottoms_w = '';
                data.topbrandcloud = '';
                data.noofb2bcustomers = '';
                data.noofoutlets = '';
                data.teammembercount = '';
                data.website = '';
                data.tallyserialno = '';
                data.remarks = '';

                setData({});
                //setActivities([]);
                // setLeadType([]);
                //setLeadsGenerated(null);

                window.location.reload();
            }

        })

    }


    return (
       
            <div id="page">
            <div id="details_main" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={val}>

                <form id="details_form" onSubmit={tallyrec} style={{ backgroundColor: '#fff', padding: '16px', borderRadius: '8px', boxShadow: '0 4px 4px black', width: '100%', maxWidth: '1000px', margin: '0 0 0 10px' }}>

                    <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px', textAlign: 'center' }}> Engagement Form </h2>

                    <div id="subform" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>

                        {/* Select Partner Name */}
                        <div>
                            <label htmlFor="partnerName" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a', fontWeight: 'bold' }}>Select AP:</label>
                            <select
                                id="partnername"
                                name="partnername"
                                style={{ width: '45%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}

                                onChange={handler}
                            >
                                <option value="" disabled selected>Select ap</option>
                                {uniquePartners.map((partner, index) => (
                                    <option key={index} value={partner}>{partner}</option>
                                ))}
                            </select>
                        </div>

                        <div style={{ margin: '0 0 0 -200px' }}>
                            <label htmlFor="cp" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a', fontWeight: 'bold' }}>CP Name:</label>
                            <input alignItems="center"
                                type="text"
                                id="cp"
                                name="cp"
                                value={details.cp}
                                style={{ width: '65%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler} readOnly
                            />
                        </div>

                        {/* Date of Visit */}
                        <div>
                            <label htmlFor="dateOfVisit" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a', fontWeight: 'bold' }}>Date of Visit:</label>
                            <input
                                type="date"
                                id="dateofvisit"
                                name="dateofvisit"
                                style={{ width: '43%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div style={{ margin: '0 0 0 -200px' }}>
                            <label htmlFor="cp" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a', fontWeight: 'bold' }}>CP Region:</label>
                            <input
                                type="text"
                                id="location"
                                name="location"
                                value={details.location}
                                style={{ width: '65%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler} readOnly
                            />
                        </div>
                        {/* Activities Done */}
                        <div style={{ gridColumn: 'span 2' }} >
                            <label htmlFor="activities" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a', fontWeight: 'bold' }}>Activities Done:</label>
                            {/* <textarea
                            id="activities"
                            name="activities"
                            rows="3"
                            style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px',margin:'0 0 0 -10px' }} onChange={handler}
                        ></textarea> */}
                            <div className="checkbox-container" >
                                {options.map((option) => (
                                    <div key={option.value} className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id={option.value}
                                            name="activities"
                                            value={option.value}
                                            checked={selectedOptions.includes(option.value)}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor={option.value}>{option.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>


                        {/* Lead Type */}
                        <div >
                            <label htmlFor="leadtype" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a', fontWeight: 'bold' }}>Lead Type:</label>
                            <input
                                type="checkbox"
                                name="TP"
                                value="TP"
                                onChange={handleLeadTypeChange}
                            />
                            <label>&nbsp;TP</label>&emsp;
                            <input
                                type="checkbox"
                                name="TSS"
                                value="TSS"
                               
                                onChange={handleLeadTypeChange}
                            />
                            <label>&nbsp;TSS</label>&emsp;
                            <input
                                type="checkbox"
                                name="Others"
                                value="Others"
                                
                                onChange={handleLeadTypeChange}
                            />
                            <label>&nbsp;Others</label>
                        </div><br></br>
                        {/* Custom Number */}
                        {/* <div>
                            <label htmlFor="leadsnumber" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Lead Count:</label>
                            <input
                                type="text"
                                id="leadsumber"
                                name="leadsnumber"
                                value={data.customnumber}
                                style={{ width: '95%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div> */}


                        {/* Year of Incorporation */}
                        {/* <div>
                            <label htmlFor="yearOfIncorporation" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Year of Incorporation:</label>
                            <input
                                type="number"
                                id="yearofinc"
                                name="yearofinc"
                                min="1900"
                                max="2100"
                                style={{ width: '80%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div> */}

                        {/* Turnover */}
                        {/* <div>
                            <label htmlFor="previousyearturnover" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Previous year Annual Turnover:</label>
                            <input
                                type="text"
                                id="prevturnover"
                                name="prevturnover"
                                style={{ width: '95%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div> */}

                        {/* Annual Turnover */}
                        {/* <div>
                            <label htmlFor="presentyearTurnover" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Present Year Annual Turnover:</label>
                            <input
                                type="text"
                                id="presturnover"
                                name="presturnover"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div> */}

                        {/* <div>
                            <label htmlFor="topbrandh/w" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Top Bottom H/W:</label>
                            <input
                                type="text"
                                id="topbottom_w"
                                name="topbottomh_w"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div><div>
                            <label htmlFor="topbrands/w" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Top Bottom S/W:</label>
                            <input
                                type="text"
                                id="topbottoms_w"
                                name="topbottoms_w"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div>
                            <label htmlFor="topbrandcloud" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Top Brand Cloud Soln.:</label>
                            <input
                                type="text"
                                id="topbrandcloud"
                                name="topbrandcloud"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div>
                            <label htmlFor="no.ofcustomers" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>No.Of B2B Customers:</label>
                            <input
                                type="number"
                                id="noofb2bcustomers"
                                name="noofb2bcustomers"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div>
                            <label htmlFor="no.ofoutlets" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>No.Of Outlets:</label>
                            <input
                                type="int"
                                id="noofoutlets"
                                name="noofoutlets"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div>
                            <label htmlFor="teammembercount" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Team Member Count:</label>
                            <input
                                type="int"
                                id="teammembercount"
                                name="teammembercount"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div>
                            <label htmlFor="website" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Website:</label>
                            <input
                                type="text"
                                id="website"
                                name="website"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div>
                        <div>
                            <label htmlFor="tallyserialno" style={{ display: 'block', marginBottom: '8px', fontSize: '14px', color: '#4a4a4a' }}>Tally Serial NO.:</label>
                            <input
                                type="int"
                                id="tallyserialno"
                                name="tallyserialno"
                                style={{ width: '85%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                            />
                        </div> */}
                        <div className="container">
                            <h2 className="header">Tally Feature Usage</h2>

                            {Object.keys(initialState).map((key) => (
                                <div key={key} className="feature" >
                                    <div style={{display:"flex",border:'solid 1px lightgrey',height:'40px'}}>
                                    <div className="label-container" >
                                        <label>&#x2605; {labels[key]}</label>
                                    </div>
                                    <div className="input-container">
                                        <input
                                            type="checkbox"
                                            name={key}
                                            value="Yes"
                                            checked={checkboxes[key] === 'Yes'}
                                            onChange={handleOptionCheckbox}
                                        />
                                        <label style={{margin:'10px 0 0 0'}}>Yes</label>&emsp;
                                        <input
                                            type="checkbox"
                                            name={key}
                                            value="No"
                                            checked={checkboxes[key] === 'No'}
                                            onChange={handleOptionCheckbox}
                                        />
                                        <label style={{margin:'10px 0 0 0'}}>No</label>
                                        </div>
                                    </div>
                                </div>
                            ))}





                            {/* Remarks */}
                            <div style={{ gridColumn: 'span 2' }}>
                                <label htmlFor="remarks" style={{ display: 'block', marginBottom: '8px', fontSize: '14px',fontWeight:'bold' }}>Remarks:</label>
                                <textarea
                                    id="remarks"
                                    name="remarks"
                                    rows="3"
                                    style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handler}
                                ></textarea>
                            </div><br></br>

                            {/* Register Button */}
                            <div id="regbtn" style={{ gridColumn: 'span 2' }}>
                              <center>  <button
                                    type="submit"
                                    style={{
                                        width: '100%',
                                        padding: '12px',
                                        backgroundColor: '#1c425f',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontSize: '16px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Submit
                                </button></center>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
            </div>
    )

}