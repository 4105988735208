import React, { useState } from 'react';
import './css/sample.css'
const PartnerForm = () => {
  const [formData, setFormData] = useState({
    cp: '',
    partnerName: '',
    adminEmail: '',
    roleType: '',
    yearOfIncorp: '',
    annualTO22_23: '',
    annualTO23_24: '',
    topBrandHW: '',
    topBrandSW: '',
    topBrandCloud: '',
    b2bCustomers: '',
    outlets: '',
    teamMembers: '',
    websiteLink: '',
    tallySerialNo: '',
    remarks: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData); // For demonstration purposes
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        User Assigned CP:
        <input type="text" value={formData.cp} onChange={(e) => setFormData({ ...formData, cp: e.target.value })} />
      </label>
      <label>
        Partner Name:
        <input type="text" value={formData.partnerName} onChange={(e) => setFormData({ ...formData, partnerName: e.target.value })} />
      </label>
      <label>
        Partner Account Admin Email ID:
        <input type="email" value={formData.adminEmail} onChange={(e) => setFormData({ ...formData, adminEmail: e.target.value })} />
      </label>
      <label>
        Partner Role Type:
        <input type="text" value={formData.roleType} onChange={(e) => setFormData({ ...formData, roleType: e.target.value })} />
      </label>
      <label>
        Year of Incorporation (YYYY):
        <input type="text" value={formData.yearOfIncorp} onChange={(e) => setFormData({ ...formData, yearOfIncorp: e.target.value })} />
      </label>
      <label>
        Annual Turnover FY-22-23 (Lacs):
        <input type="text" value={formData.annualTO22_23} onChange={(e) => setFormData({ ...formData, annualTO22_23: e.target.value })} />
      </label>
      <label>
        Annual Turnover FY-23-24 (Lacs):
        <input type="text" value={formData.annualTO23_24} onChange={(e) => setFormData({ ...formData, annualTO23_24: e.target.value })} />
      </label>
      <label>
        Top Brand Hardware:
        <input type="text" value={formData.topBrandHW} onChange={(e) => setFormData({ ...formData, topBrandHW: e.target.value })} />
      </label>
      <label>
        Top Brand Software:
        <input type="text" value={formData.topBrandSW} onChange={(e) => setFormData({ ...formData, topBrandSW: e.target.value })} />
      </label>
      <label>
        Top Brand Cloud Solution:
        <input type="text" value={formData.topBrandCloud} onChange={(e) => setFormData({ ...formData, topBrandCloud: e.target.value })} />
      </label>
      <label>
        Number of B2B customers:
        <input type="text" value={formData.b2bCustomers} onChange={(e) => setFormData({ ...formData, b2bCustomers: e.target.value })} />
      </label>
      <label>
        Number of Outlets:
        <input type="text" value={formData.outlets} onChange={(e) => setFormData({ ...formData, outlets: e.target.value })} />
      </label>
      <label>
        Team Member Count:
        <input type="text" value={formData.teamMembers} onChange={(e) => setFormData({ ...formData, teamMembers: e.target.value })} />
      </label>
      <label>
        Website / Google Site / FB Page link:
        <input type="text" value={formData.websiteLink} onChange={(e) => setFormData({ ...formData, websiteLink: e.target.value })} />
      </label>
      <label>
        Tally Serial No.:
        <input type="text" value={formData.tallySerialNo} onChange={(e) => setFormData({ ...formData, tallySerialNo: e.target.value })} />
      </label>
      <label>
        Remarks:
        <textarea value={formData.remarks} onChange={(e) => setFormData({ ...formData, remarks: e.target.value })} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default PartnerForm;
