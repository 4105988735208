import { Link, useNavigate } from "react-router-dom";
import './css/dasboard.css';
import { useEffect, useRef, useState } from "react";
import './css/tallydashboard.css'

import axios from "axios";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import 'jspdf-autotable';

export default function Dashboard() {
  const componentPDF = useRef();
  const [activeTab, setActiveTab] = useState("users");
  const [data, setData] = useState([]);
  const [cpdata, setCPData] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_TALLY_API}/cpsubmitdata`).then((res) => {
      setCPData(res.data);
    });
    axios.get(`${process.env.REACT_APP_TALLY_API}/userslist`).then((res) => {
      setData(res.data);
    });
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };



  // passing id..
   const usenav = useNavigate()
  const navigateupdatepage = (id) =>{
    usenav('/updateData', { state: {id:id} });
  }

  const st ={
    borderRadius:'5px'
  }

  
  return (
    <section >
      <div id="list">
        <div id="data_button">
        <div id="add" >
          <details id="details">
            <summary style={{ fontWeight: 'bold', textDecoration: 'underline',cursor:'pointer'}}>Click Here To Add Users</summary>
            <button id="cp_user"><Link to={'users'} style={{ textDecoration: 'none', color: 'rgb(9, 36, 37)' }}>Add CP User</Link></button>&emsp;
            <button id="cp_user"><Link to={'apusers'} style={{ textDecoration: 'none', color: 'rgb(9, 36, 37)' }}>Add AP User</Link></button>
          </details>
        </div>

        <div id="table_view" >
          <button className="checkdata1" onClick={() => handleTabChange("users")} style={{cursor:'pointer'}}>Registered User's</button>&emsp;
          <button className="checkdata2" onClick={() => handleTabChange("cps")} style={{cursor:'pointer'}}>Records Created By User's</button>
        </div>
        </div>

        <div >

          {activeTab === "users" && (
            <div>
              <table id="userstable">
                <caption align="center" style={{ fontWeight: 'bold', fontSize: '20px', color: 'white', borderRadius: '0 10px 0 10px', background: 'grey', margin: '10px 0 0 10px', width: '150px' }}>CP's Registered</caption><br></br>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <th>CPName</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                  {data.map((item, index) => (
                    // Check if item.role is not "admin"
                    item.role !== "Admin" && (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>{item.cp}</td>
                        <td>{item.username}</td>
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                        

                        <td><button type="button" className="assignbtn" onClick={()=>navigateupdatepage(item.id)}>
                          Assign AP</button></td>

                      </tr>
                    )
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        
        <div id="recordstable" ref={componentPDF} >
          
          {activeTab === "cps" && (
            <div style={{ overflowX: 'auto' }}><br></br>
                 <div id="dt" style={{margin:'15px 0 0 500px',...st }}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-success mb-3"
                    table="table-to-xls"
                    filename="tallydata"
                    sheet="Recordsdata"
                    buttonText="-> DOWNLOAD EXCEL"
                    borderRadius="5px"  />
                </div>
              <table id="table-to-xls" style={{ width: '100%' }}>
             
                <caption align="center" style={{ fontWeight: 'bold', fontSize: '20px', color: 'white', borderRadius: '0 10px 0 10px', background: 'grey', margin: '10px 0 0 10px ', width: '250px' }} >CP Generated Records</caption><br></br>

                <tbody>
                  <tr>
                    <th>ID</th>
                    <th>CPName</th>
                    <th>CP Region</th>
                    <th>Assigned User</th>
                    <th>Partner Name</th>
                    <th>Activities</th>
                    <th>Lead Type</th>
                    <th>Date Of Visit</th>
                    <th>E-Invoicing</th>
                    <th>E-Way Bill</th>
                    <th>Dashboard</th>
                    <th>Excel Import</th>
                    <th>WhatsApp on Tally Prime</th>
                    <th>Tally Prime on AWS</th>
                    <th>MSME Reports</th>
                    <th>Invoicing with Logo</th>
                    <th>QR Code Enablement for Payment</th> 
                    <th>Price List</th> 
                    <th>Customised Terms & Conditions in Invoices</th> 
                    <th>Recorder Created Date</th>
                    <th>Recorder Created Time</th>
                    <th>Remarks</th>
                  </tr>
                  {cpdata.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.cp}</td>
                      <td>{item.location}</td>
                      <td>{item.assigned_cp}</td>
                      <td>{item.partner_name}</td>
                      <td>{item.activities}</td>
                      <td>{item.lead_type}</td>
                      <td>{item.date_of_visit}</td>
                      <td>{item.eInvoicing}</td>
                      <td>{item.eWayBill}</td>
                      <td>{item.dashboard}</td>
                      <td>{item.excelImport}</td>
                      <td>{item.whatsappOnTallyPrime}</td>
                      <td>{item.tallyPrimeOnAWS}</td>
                      <td>{item.msmeReports}</td>
                      <td>{item.invoicingWithLogo}</td>
                      <td>{item.qrCodeEnablementForPayment}</td>
                      <td>{item.priceList}</td>
                      <td>{item.CustomisedTermsandConditionsinInvoices}</td>
                      <td>{item.rec_created_date}</td>
                      <td>{item.rec_created_time}</td>
                      <td>{item.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}