import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function UserRegistrationForm (){
    const Nav = useNavigate();
  const [data, setData] = useState({usernamename: '',  email: '',cp:'',location:'', role: '' });
  const [val, setVal] = useState(0);
  



  const handler = (e) => {
    const { name, value } = e.target;

    setData((prevState) =>
      ({ ...prevState, [name]: value }));

  }

  const back = () =>{
    Nav('/tallydashboard');
}

  const register = () =>{
    const userdetails = {username: data.username , email:data.email ,cp:data.cp,location:data.location, role: data.role}
    axios.post(`${process.env.REACT_APP_TALLY_API}/register`, userdetails).then((res) => {
      console.log(res.data);
      if (res !== '') {
        alert('registration successfully completed');
        Nav('/users')
        setVal(val + 1)
        data.username = '';
        data.email = '';
        data.cp = '';
        data.location = '';
        data.role = '';
        
      }

    })
   
  }

    return(
        <section>
            {/* <h1>Users Login</h1> */}
            <div key={val}>
            
            {/* Button to open modal */}
            
            {/* Modal */}
            
                <div style={{ position: 'fixed',margin:'50px 0 0 0', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fefefe', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', zIndex: '999', width: '80%', maxWidth: '500px' }}>
                    {/* Modal content */}
                    <FaArrowLeft onClick={back}  style={{cursor:'pointer'}}/><br></br><br></br>
                    <div>
                                               {/* Registration form */}
                        <form onSubmit={register}>
                            <label htmlFor="username" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Username:</label>
                            <input type="text" id="username" name="username"  onChange={handler}  style={{ width: 'calc(100% - 22px)', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }} required />
                            <label htmlFor="email" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Email:</label>
                            <input type="email" id="email" name="email" onChange={handler} style={{ width: 'calc(100% - 22px)', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }} required />
                            <label htmlFor="cp" style={{ marginBottom: '5px', fontWeight: 'bold' }}>CPName:</label>
                            <input type="text" id="cp" name="cp"  onChange={handler}  style={{ width: 'calc(100% - 22px)', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }} required />
                            <label htmlFor="location" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Location:</label>
                            <select id="location" name="location"  onChange={handler}  style={{ width: 'calc(100% - 22px)', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }} > 
                              <option value="select">Select</option>
                              <option value="Bengaluru">Bengaluru</option>
                              <option value="Chennai">Chennai</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Rest of Karnataka">Rest of Karnataka</option>
                              <option value="Rest of Tamil Nadu">Rest of Tamil Nadu</option>
                              <option value="TSAP">TSAP</option>
                            </select>
                            <label htmlFor="role" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Role:</label>
                            <select id="role" name="role" onChange={handler} style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }}>
                                <option value="select">Select</option>
                                <option value="User">User</option>
                                <option value="Admin">Admin</option>
                            </select>
                            <input type="submit" value="Register" style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer',padding:'10px',borderRadius:'5px',margin:'0 0 0 200px',width:'100px' }} />
                        </form>
                    </div>
                </div>
            
        </div>

        

        </section>
    )
}