import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import React from 'react';
import Select from 'react-select';
import { region } from "./App";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";

export default function Update() {
    const { sampleData } = useContext(region);
    const [data, setData] = useState({ username: '', email: '', selectedOptions: null });

    const Nav = useNavigate();

    const location = useLocation();
    const locationState = location.state || {};
    const { id } = locationState;
    console.log(id);

    React.useEffect(() => {
        if (id) { // Check if 'id' is truthy before making the axios request
            axios.get(`${process.env.REACT_APP_TALLY_API}/singleuser/${id}`).then((res) => {
                setData({ username: res.data[0].username, email: res.data[0].email, selectedOptions: null });
                console.log(res.data[0]);
            }).catch((error) => {
                console.error('Error fetching user data:', error);
            });
        }
    }, [id]); // Include 'id' in the dependency array

    const back = () => {
        Nav('/tallydashboard');
    }

    const update = (e) => {
        e.preventDefault(); // Prevent default form submission
        console.log(data); // Ensure `data` contains the necessary information
        axios.post(`${process.env.REACT_APP_TALLY_API}/updateuser/${id}`, data)
            .then((res) => {
                console.log(res.data); // Log the response from the server
                if (res.data) { // Check if the response contains data
                    alert('Details updated successfully!!!!');
                    console.log({ id }); // Log the ID if needed
                    Nav('/tallydashboard'); // Navigate to tallydashboard after successful update
                } else {
                    alert('Failed to update details. Please try again.');
                }
            })
            .catch((error) => {
                console.error('Error updating details:', error); // Log any errors that occur during the request
                alert('An error occurred while updating details. Please try again later.');
            });
    };

    const handler = (selectedOption) => {
        setData((prevState) => ({
            ...prevState,
            selectedOptions: selectedOption
        }));
    };

    const options = sampleData.map(partner => ({
        value: partner.partner_name,
        label: partner.partner_name
    }));

    return (
        <section>
            <div>
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fefefe', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', zIndex: '999', width: '80%', maxWidth: '500px' }}>
                    <FaArrowLeft onClick={back} style={{ cursor: 'pointer' }}/><br/><br/>
                    <div>
                        <form onSubmit={update}>
                            <label htmlFor="username" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Username:</label>
                            <input type="text" id="username" name="username" onChange={handler} value={data.username} style={{ width: 'calc(100% - 22px)', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }} required />
                            <label htmlFor="email" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Email:</label>
                            <input type="email" id="email" name="email" onChange={handler} value={data.email} style={{ width: 'calc(100% - 22px)', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxSizing: 'border-box' }} required />
                            <label htmlFor="role" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Select Ap</label>

                            <Select
                                options={options}
                                isMulti
                                onChange={handler}
                                value={data.selectedOptions}
                                placeholder="Select ap partner name"
                            />
                            <input type="submit" value="Register" style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', padding: '10px', borderRadius: '5px', margin: '10px 0 0 200px', width: '100px' }} />
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
