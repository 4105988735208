import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import './css/login.css';

export default function Login() {
    const [data, setData] = useState({ username: '', email: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_TALLY_API}/login`, data);
            const userData = response.data[0];

            localStorage.setItem('username', userData.username);
            localStorage.setItem('role', userData.role);

            if (userData.role === 'Admin') {
                Swal.fire({
                    title: 'Logged in successfully',
                    text: `Welcome ${localStorage.getItem('username')}`,
                    icon: 'success',
                });
                navigate('/tallydashboard');
            } else if (userData.role === 'User') {
                Swal.fire({
                    title: 'Logged in successfully',
                    text: `Welcome ${localStorage.getItem('username')}`,
                    icon: 'success',
                });
                navigate('/details', { state: { username: userData.username, id: userData.id } }); 
            }
        } catch (error) {
            console.error('Login error:', error);
            Swal.fire({
                title: 'Error',
                text: 'Login failed. Please try again.',
                icon: 'error',
            });
        }
    };

    return (
        <section>
            <div className="login">
                

                <div className="login-form">
                    <form onSubmit={handleSubmit}>
                    <div align="center" className="login-header" style={{background:'black',color:'white',borderRadius:'10px',height:'50px'}}>
                    <h1 style={{margin:'10px 0 0 0'}}>LOGIN</h1>
                </div><br/>
                        <label htmlFor="username">User Name</label><br />
                        <input type="text" id="username" name="username" className="login-input" placeholder="Enter your username" onChange={handleChange} /><br /><br />
                        <label htmlFor="email">Email</label><br />
                        <input type="email" id="email" name="email" className="login-input" placeholder="Enter your Email" onChange={handleChange} /><br /><br />

                        <button type="submit" className="login-submit">Login</button>
                    </form>
                </div>
            </div>
        </section>
    );
}
